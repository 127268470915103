import React from "react";
import { MdClose, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BsFacebook } from "react-icons/bs";
import { SiInstagram } from "react-icons/si";
import { FaTwitter } from "react-icons/fa";
import { MdOutlineWhatsapp } from "react-icons/md";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}
const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  return (
    <div
      className={`flex fixed z-30 bg-blue-100 flex-col justify-between h-screen left-0 top-0 bottom-0  w-3/4 md:w-1/2  px-[20px] py-[20px] ${
        isOpen ? "translate-x-0" : "hidden -translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div>
        <div className="flex flex-row items-center justify-between">
          <p className="font-bold ">Tantra</p>
          <span>
            <MdClose size={30} onClick={toggleSidebar} />
          </span>
        </div>

        <div className="py-6">
          <ul>
            <li className="border-b-[1px] border-blue-300 py-3">
              <div className="flex justify-between items-center">
                <p>Home</p>
                <span>
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
            </li>
            <li className="border-b-[1px] border-blue-300 py-3">
              <div className="flex justify-between items-center">
                <p>Pages</p>
                <span>
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
            </li>
            <li className="border-b-[1px] border-blue-300 py-3">
              <div className="flex justify-between items-center">
                <p>Courses</p>
                <span>
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
            </li>
            <li className="border-b-[1px] border-blue-300 py-3">
              <div className="flex justify-between items-center">
                <p>Blog</p>
                <span>
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
            </li>
            <li>
              <div className="flex justify-between items-center">
                <p>Contacts</p>
                <span>
                  <MdOutlineKeyboardArrowRight />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col">
        <p className="font-bold">Folow Us</p>

        <div className="flex gap-2">
          <div className="w-[50px] h-[50px] rounded-lg bg-blue-800 flex flex-col justify-center items-center">
            <BsFacebook color="white" />
          </div>
          <div className="w-[50px] h-[50px] rounded-lg bg-gradient-to-r from-pink-600 to-yellow-500 flex flex-col justify-center items-center">
            <SiInstagram />
          </div>
          <div className="w-[50px] h-[50px] rounded-lg bg-blue-500 flex flex-col justify-center items-center">
            <FaTwitter />
          </div>
          <div className="w-[50px] h-[50px] rounded-lg bg-green-500 flex flex-col justify-center items-center">
            <MdOutlineWhatsapp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
