import React from "react";
import { GiGraduateCap } from "react-icons/gi";
import { FaFacebookF } from "react-icons/fa6";
import { CiTwitter } from "react-icons/ci";
import { RiVimeoFill } from "react-icons/ri";
import { LiaLinkedinIn } from "react-icons/lia";
import { Swiper, SwiperSlide } from "swiper/react";

const Footer = () => {
  return (
    <div className="px-[20px] 2xs:px-[120px]  py-[30px] bg-black text-gray-300  w-full bottom-0">
      <div className="flex flex-col ">
        <div className="lg:flex lg:flex-row grid grid-cols-1 md:grid-cols-2 justify-between">
          <div className="flex flex-col ">
            <div className="flex flex-row gap-3 items-center">
              <GiGraduateCap size={40} />
              <h2 className="font-semibold text-[20px]"></h2>
            </div>
            <p>
              Great lesson ideas and lesson plans for <br /> ESL teachers!
              Educators can customize <br /> lessons as best plans to knowledge.
            </p>
            <div className="py-4">
              <div className="flex flex-row justify-start items-center gap-4">
                <div className="bg-gray-700 rounded-full py-1 px-1">
                  <FaFacebookF size={30} />
                </div>
                <div className="bg-gray-700 rounded-full py-1 px-1">
                  <CiTwitter size={30} />
                </div>
                <div className="bg-gray-700 rounded-full py-1 px-1">
                  <RiVimeoFill size={30} />
                </div>
                <div className="bg-gray-700 rounded-full py-1 px-1">
                  <LiaLinkedinIn size={30} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="pb-4 font-semibold text-[20px]">Online Platform</h2>
            <ul className="flex flex-col gap-4">
              <li>Proper Guidelines</li>
              <li>Digital Library</li>
              <li>Compare Us</li>
              <li>Become Instructor</li>
              <li>Build Career</li>
            </ul>
          </div>
          <div>
            <h2 className="pb-4 font-semibold text-[20px]">Browse Courses</h2>
            <ul className="flex flex-col gap-4">
              <li>Development</li>
              <li>Business</li>
              <li>Health & Fitness</li>
              <li>Life Styles</li>
              <li>Photography</li>
            </ul>
          </div>
          <div>
            <h2 className="pb-4 font-semibold text-[20px]">
              Insight Community
            </h2>
            <ul className="flex flex-col gap-4">
              <li>Global Partners</li>
              <li>Forum</li>
              <li>Help & Support</li>
              <li>Community</li>
              <li>Documentation</li>
            </ul>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Footer;
