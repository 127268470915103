import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa6";
import { AiOutlineEye } from "react-icons/ai";
import BookCard from "./../components/courses/BookCard";
import { Link } from "react-router-dom";

const Courses = () => {
  const [isCategoryClicked, setIsCategoryClicked] = useState<boolean>(false);
  const [isPriceClicked, setIsPriceClicked] = useState<boolean>(false);
  const [isLevelClicked, setIsLevelClicked] = useState<boolean>(false);
  const [isLanguageClicked, setIsLanguageClicked] = useState<boolean>(false);
  const [isDurationClicked, setIsDurationClicked] = useState<boolean>(false);

  const clickToggleCategory = () => setIsCategoryClicked(!isCategoryClicked);
  const clickTogglePrice = () => setIsPriceClicked(!isPriceClicked);
  const clickToggleLevel = () => setIsLevelClicked(!isLevelClicked);
  const clickToggleLanguage = () => setIsLanguageClicked(!isLanguageClicked);
  const clickToggleDuration = () => setIsDurationClicked(!isDurationClicked);

  return (
    <div className="flex flex-col w-full  pt-[120px] px-[10px] md:px-[50px] lg:px-[100px] xl:px-[120px]">
      <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row w-full">
        <div className=" md:w-1/2 lg:w-1/4 xl:w-1/4">
          <div className="border border-gray-200 shadow-md rounded-lg px-2 my-5 w-full">
            <div className="flex flex-row justify-between items-center  py-4">
              <h2 className="font-semibold text-[20px]">Categories</h2>
              <span onClick={clickToggleCategory}>
                {isCategoryClicked ? (
                  <MdKeyboardArrowDown size={25} />
                ) : (
                  <IoIosArrowUp size={25} />
                )}
              </span>
            </div>
            <ul className={`${isCategoryClicked ? "hidden" : ""}   `}>
              <li className="py-2 px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Business (12)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Development (9)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Data Science (25)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Arts & Design (10)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Finance (8)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Marketing (22)
                </label>
              </li>

              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Health & Fitness (5)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Photography (18)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100 "
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Music (21)
                </label>
              </li>
            </ul>
          </div>
          <div className="border border-gray-200 shadow-md rounded-lg px-2 my-5  w-full">
            <div className="flex flex-row justify-between items-center  py-4">
              <h2 className="font-semibold text-[20px]">Price</h2>
              <span onClick={clickTogglePrice}>
                {isPriceClicked ? (
                  <MdKeyboardArrowDown size={25} />
                ) : (
                  <IoIosArrowUp size={25} />
                )}
              </span>
            </div>
            <ul className={`${isPriceClicked ? "hidden" : ""}   `}>
              <li className="py-2 px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> All (85)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Free (21)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Paid (54)
                </label>
              </li>
            </ul>
          </div>
          <div className="border border-gray-200 shadow-md rounded-lg px-2 my-5  w-full">
            <div className="flex flex-row justify-between items-center  py-4">
              <h2 className="font-semibold text-[20px]">Level</h2>
              <span onClick={clickToggleLevel}>
                {isLevelClicked ? (
                  <MdKeyboardArrowDown size={25} />
                ) : (
                  <IoIosArrowUp size={25} />
                )}
              </span>
            </div>
            <ul className={`${isLevelClicked ? "hidden" : ""}   `}>
              <li className="py-2 px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> All levels (5)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Beginner (36)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Intermediate (25)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Expert (32)
                </label>
              </li>
            </ul>
          </div>
          <div className="border border-gray-200 shadow-md rounded-lg px-2 my-5  w-full">
            <div className="flex flex-row justify-between items-center  py-4">
              <h2 className="font-semibold text-[20px]">Language</h2>
              <span onClick={clickToggleLanguage}>
                {isLanguageClicked ? (
                  <MdKeyboardArrowDown size={25} />
                ) : (
                  <IoIosArrowUp size={25} />
                )}
              </span>
            </div>
            <ul className={`${isLanguageClicked ? "hidden" : ""}   `}>
              <li className="py-2 px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> English (21)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Hindi (12)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> French (7)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Japanese (10)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Korean (5)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Japanese (10)
                </label>
              </li>

              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span>Spanish (14)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Italian (8)
                </label>
              </li>
            </ul>
          </div>
          <div className="border border-gray-200 shadow-md rounded-lg px-2 my-5  w-full">
            <div className="flex flex-row justify-between items-center  py-4">
              <h2 className="font-semibold text-[20px]">Duration</h2>
              <span onClick={clickToggleDuration}>
                {isDurationClicked ? (
                  <MdKeyboardArrowDown size={25} />
                ) : (
                  <IoIosArrowUp size={25} />
                )}
              </span>
            </div>
            <ul className={`${isDurationClicked ? "hidden" : ""}   `}>
              <li className="py-2 px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> Less than 1 Hours (24)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> 1-2 Hours (36)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> 2-5 Hours (25)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> 6-7 Hours (32)
                </label>
              </li>
              <li className="py-2  px-4">
                <label className="flex flex-row gap-2 items-center">
                  <input
                    className="bg-slate-100"
                    type="checkbox"
                    value="option1"
                    //   checked={selectedValue === "option1"}
                    //   onChange={handleRadioChange}
                  />
                  <span className="gap-4"></span> 7-10 Hours (11)
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div className=" md:w-1/2 lg:w-3/4 xl:w-3/4 px-5 flex justify-center items-center">
          <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4">
            <Link to="/courses/1">
              <BookCard />
            </Link>

            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
            <Link to="/courses/1">
              <BookCard />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
