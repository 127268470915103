import React from "react";
import { CiHeart } from "react-icons/ci";
import { AiOutlineEye } from "react-icons/ai";

const BookCard = () => {
  return (
    <div className="mx-1 py-2">
      <div className="relative group">
        <div className="overflow-hidden">
          <img
            className="w-full h-auto object-cover transition-transform duration-500 ease-in-out transform hover:scale-110 "
            src="/assets/product-thumb-01.png"
            alt="book1"
          />
        </div>

        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-0 flex justify-evenly items-center p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <CiHeart
            size={40}
            className="text-white cursor-pointer bg-gray-300 px-2 py-2 rounded-full"
            color="black"
          />
          <AiOutlineEye
            size={40}
            className="text-white cursor-pointer bg-gray-300 px-2 py-2  rounded-full"
            color="black"
          />
        </div>
      </div>
      <div className="flex flex-col items-center py-3">
        <p className="font-semibold">Turn Yourself</p>
        <span className="font-semibold">$24.00</span>
      </div>
    </div>
  );
};

export default BookCard;
