import React from "react";

const Platform = () => {
  return (
    <div className="bg-cover bg-no-repeat bg-center lg:py-[50px]  pt-[250px] px-[20px] sm:px-[50px] md:px-[80px] lg:px-[100px] xl:px-[120px]">
      <div className="container">
        <div className="lg:grid grid-cols-12 gap-[30px]">
          <div className="xl:col-span-5 lg:col-span-6 col-span-12">
            <div className="text-green-400 font-semibold text-[20px]">
              Instant eBooks for Every Passion
            </div>
            <h4 className="text-[30px] font-bold">
              Unleash Your Reading{" "}
              <span className="shape-bg text-red-300">Courses</span> Adventure
              on Tantra
            </h4>
            <div className="py-[10px] text-gray-400">
              Explore captivating reads: thrillers, romance, self-help,
              business. Bestsellers, new releases, free titles—your fingertips'
              delight!.
            </div>
            <ul className=" space-y-2 pt-8">
              <li className="flex flex-wrap">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src="/assets/ok.svg" alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                    stories with a 9/10 satisfaction rate
                  </h4>
                </div>
              </li>
              <li className="flex flex-wrap">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src="/assets/ok.svg" alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                    96% completion rate
                  </h4>
                </div>
              </li>
              <li className="flex flex-wrap">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src="/assets/ok.svg" alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                    a friendly, expert-led environment
                  </h4>
                </div>
              </li>
            </ul>
            <button className="pt-8">Explore Our Courses</button>
          </div>
          <div className="xl:col-span-7 lg:col-span-6 col-span-12 lg:pt-0 md:pt-10 pt-6">
            <img
              src="/assets/about2.png"
              alt=""
              className=" mx-auto block w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
