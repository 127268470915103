import React from "react";

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r fade-in-out">
      <div className="bg-fixed bg-no-repeat bg-center"> 
        <img src="./assets/LogoPackupdated.png" style={{ width: "250px" }} />
      </div>
    </div>
  );
};

export default Loading;
