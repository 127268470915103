import React from "react";

const About = () => {
  return (
    <div className="w-full px-[20px] md:px-[50px] lg:px-[100px] xl:px-[120px] py-[150px]">
      <div className="">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
          <div>
            <img src="/assets/about.png" alt="aboutOne" className=" mx-auto" />
          </div>
          <div>
            <div className="text-green-400 font-semibold text-[20px]">
              About Tantra
            </div>
            <h4 className="font-bolt text-[30px]">
              Your Gateway to Literary Treasures
              <br />
              <span className="shape-bg text-red-400">Achieve</span>
            </h4>
            <div>
              Discover, download, and delight in bestsellers, hidden gems, and
              free reads!.
            </div>
            <ul className=" list-item space-y-6 pt-8">
              <li className="flex">
                <div className="flex-none mr-6">
                  <div className="h-[72px] w-[72px] rounded-full">
                    <img
                      src="/assets/light.svg"
                      alt="lightImg"
                      className=" w-full h-full object-cover rounded-full block"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">Our Mission</h4>
                  <div>
                    Transforming Lives Through Books. Discover and download
                    <br />
                    eBooks that ignite your imagination effortlessly.
                  </div>
                </div>
              </li>
              <li className="flex">
                <div className="flex-none mr-6">
                  <div className="h-[72px] w-[72px] rounded-full">
                    <img
                      src="/assets/target.svg"
                      alt=""
                      className=" w-full h-full object-cover rounded-full block"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">Our Vision</h4>
                  <div>
                    A World Immersed in Reading We envision a future where
                    <br />
                    everyone has access to the transformative power of the
                    written word.
                  </div>
                </div>
              </li>
            </ul>
            <div className="py-4 pt-8">
              <button className="py-4 px-5 bg-rose-500 rounded-md text-white">
                Read More Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
