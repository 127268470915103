import React from "react";
import PDFReader from "./../components/book/PDFViewer";

const CourseBookViewer = () => {
  return (
    <div className="min-h-full">
      <PDFReader url="/assets/kp.pdf" />
    </div>
  );
};

export default CourseBookViewer;
