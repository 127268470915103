// PDFReader.tsx
import React, { useState, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist";

// If you're serving the worker from public directory or using a CDN
pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";
interface PDFReaderProps {
  url: string;
}

const PDFReader: React.FC<PDFReaderProps> = ({ url }) => {
  const [pageNum, setPageNum] = useState(1);
  const [numPages, setNumPages] = useState(0);

  const loadDocument = async () => {
    try {
      const pdf = await pdfjsLib.getDocument(url).promise;
      setNumPages(pdf.numPages);
      renderPage(pdf, pageNum);
    } catch (error) {
      console.log("pdf errors", error);
    }
  };

  const renderPage = async (
    pdf: pdfjsLib.PDFDocumentProxy,
    pageNum: number
  ) => {
    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: 1.0 });
    const canvas = document.getElementById("pdf-canvas") as HTMLCanvasElement;
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    if (!context) {
      throw new Error("Could not obtain 2D context from canvas");
    }

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;
  };

  useEffect(() => {
    loadDocument();
  }, [url, pageNum]);

  return (
    <div>
      <canvas id="pdf-canvas"></canvas>
      <div>
        <button disabled={pageNum <= 1} onClick={() => setPageNum(pageNum - 1)}>
          Previous
        </button>
        <button
          disabled={pageNum >= numPages}
          onClick={() => setPageNum(pageNum + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PDFReader;
