import React, { useState } from "react";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";

import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";
import BookCard from "./../components/courses/BookCard";
import { Link } from "react-router-dom";

const CourseDetails = () => {
  const [isDescriptionClicked, setIsDiscriptionClicked] = useState(true);
  const [isReviewsClicked, setIsReviewClicked] = useState(false);

  const cards: any = ["", "", "", "", "", "", "", "", "", "", "", ""];

  const descriptionSelect = () => {
    setIsDiscriptionClicked(true);
    setIsReviewClicked(false);
  };
  const reviewSelect = () => {
    setIsDiscriptionClicked(false);
    setIsReviewClicked(true);
  };

  return (
    <div className="py-[120px] px-[20px] md:px-[50px] lg:px-[100px] xl:px-[120px]">
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="w-full md:w-1/2 lg:w-1/4  mr-3">
          <img
            className="h-[468px] w-[360px]"
            src="/assets/product-thumb-03.png"
            alt="bgimg"
          />
        </div>
        <div className="flex flex-col px-[20px]">
          <h2 className="font-bold text-[25px] py-2">The Almanack</h2>
          <span className="text-blue-700 font-semibold">$24.00</span>
          <p className="py-2">
            Matent maecenas nec massa viverra aci ute litora aliquam habitant
            proin commodo <br /> bibendum rutru habitant est magnis quisque
            aliquet congue vesti bulum suscipi erose <br /> tellus odio elite
            purus feugiat prim libero senes nisie gravia.
          </p>

          <div className="flex flex-col 2xs:flex-row py-7 items-start 2xs:items-center gap-7 ">
            <div className="flex gap-4 rounded-sm border border-gray-400">
              <button className="hover:bg-blue-600 px-4 py-4">
                <span className="text-gray-400 hover:text-white font-[20px]">
                  <FiMinus />
                </span>
              </button>

              <button disabled>
                <span>1</span>
              </button>

              <button className="hover:bg-blue-600 px-4 py-4">
                <span className="text-gray-400 hover:text-white">
                  <FaPlus />
                </span>
              </button>
            </div>
            <div>
              <Link to="/">
                <button className="bg-blue-600 font-semibold py-3 px-4 rounded-sm text-white hover:text-black hover:bg-yellow-500">
                  Add To Cart
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[25px]">
        <div className="flex flex-row w-full gap-2 border-b border-gray-300 py-[15px]">
          <button
            className={`${
              isDescriptionClicked ? "text-blue-400 font-bold" : ""
            }`}
            onClick={descriptionSelect}
          >
            Description
          </button>
          <button
            className={`${isReviewsClicked ? "text-blue-400 font-bold" : ""}`}
            onClick={reviewSelect}
          >
            Reviews
          </button>
        </div>

        {isDescriptionClicked && (
          <div className="py-[10px]">
            <p>
              Very clean and organized with easy to follow tutorials, Exercises,
              and solutions. This course does start from the beginning with very
              little knowledge and gives a great overview of common tools used
              for data science and progresses into more complex concepts and
              ideas. This course is amazing..! I started this course as a
              beginner and learnt a lot. Instructors are great. Query handling
              can be improved.Overall very happy with the course.
            </p>
          </div>
        )}

        {isReviewsClicked && (
          <div className="w-full flex flex-col">
            <div className="w-full py-4 flex flex-row justify-start gap-6">
              <img
                className="w-[50px] h-[50px] rounded-full"
                src="/assets/course-reviews-1.png"
                alt="profile"
              />

              <div className="flex flex-col justify-center ">
                <span className="font-bold">Sotapdi Kunda</span>
                <p className="text-gray-500">
                  Very clean and organized with easy to follow tutorials,
                  Exercises, and solutions. This course does start from the
                  beginning with very little knowledge and gives a great
                  overview of common tools used for data science and progresses
                  into more complex concepts and ideas.
                </p>
              </div>
            </div>
            <div className="w-full py-4 flex flex-row justify-start gap-6">
              <img
                className="w-[50px] h-[50px] rounded-full"
                src="/assets/course-reviews-1.png"
                alt="profile"
              />

              <div className="flex flex-col justify-center ">
                <span className="font-bold">Sotapdi Kunda</span>
                <p className="text-gray-500">
                  Very clean and organized with easy to follow tutorials,
                  Exercises, and solutions. This course does start from the
                  beginning with very little knowledge and gives a great
                  overview of common tools used for data science and progresses
                  into more complex concepts and ideas.
                </p>
              </div>
            </div>
            <div className="w-full py-4 flex flex-row justify-start gap-6">
              <img
                className="w-[50px] h-[50px] rounded-full"
                src="/assets/course-reviews-1.png"
                alt="profile"
              />
              <div className="flex flex-col justify-center ">
                <span className="font-bold">Sotapdi Kunda</span>
                <p className="text-gray-500">
                  Very clean and organized with easy to follow tutorials,
                  Exercises, and solutions. This course does start from the
                  beginning with very little knowledge and gives a great
                  overview of common tools used for data science and progresses
                  into more complex concepts and ideas.
                </p>
              </div>
            </div>
            <div className="w-full py-4 flex flex-row justify-start gap-6">
              <img
                className="w-[50px] h-[50px] rounded-full"
                src="/assets/course-reviews-1.png"
                alt="profile"
              />
              <div className="flex flex-col justify-center ">
                <span className="font-bold">Sotapdi Kunda</span>
                <p className="text-gray-500">
                  Very clean and organized with easy to follow tutorials,
                  Exercises, and solutions. This course does start from the
                  beginning with very little knowledge and gives a great
                  overview of common tools used for data science and progresses
                  into more complex concepts and ideas.
                </p>
              </div>
            </div>

            <div className="flex flex-col w-full">
              <h2 className="font-bold text-[25px]">Add a review</h2>
              <p>
                Your email address will not be published. Required fields are
                marked *
              </p>
              <div className="flex flex-col w-full">
                <div className="w-full">
                  <textarea
                    className="w-full py-[50px] px-2 rounded-md border-none outline-none"
                    rows={4}
                    placeholder="Your review"
                  />
                </div>
                <div className="flex flex-row gap-3 w-full">
                  <div className="w-1/2">
                    <input
                      className="w-full py-3  px-2 rounded-md outline-none"
                      type="text"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="w-1/2">
                    <input
                      className="w-full py-3 px-2 rounded-md outline-none"
                      type="text"
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div className="py-[15px]">
                  <button className="py-3 px-4 rounded-md text-white bg-blue-600">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="">
          <Swiper
            spaceBetween={50}
            onSlideChange={() => console.log("slide change")}
            breakpoints={{
              480: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
          >
            {cards.map((i: any, card: any) => (
              <SwiperSlide key={i}>
                <BookCard />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
