import React from "react";
import Header from "./../components/Header";
import About from "./About";
import WhyChoose from "./../components/WhyChoose";
import Platform from "./../components/Platform";

const Home = () => {
  return (
    <>
      <Header />
      <About />
      <WhyChoose />
      <Platform />
    </>
  );
};

export default Home;
