import React from "react";
import { TfiWorld } from "react-icons/tfi";
import { IoIosPeople } from "react-icons/io";
import { FaHandsHelping } from "react-icons/fa";

const WhyChoose = () => {
  return (
    <div className="h-screen  py-[160px] lg:py-[50px] xl:py-[20px] flex flex-col items-center px-[20px] justify-center  bg-cover bg-no-repeat bg-center">
      <div className="container ">
        <div className="text-center">
          <div className="text-green-400 font-semibold text-[20px]">
            Key Offerings
          </div>
          <div className="column-title text-[35px] font-bold">
            Why{" "}
            <span className="shape-bg text-rose-400 text-[30px] ">Choose</span>{" "}
            Tantra
          </div>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
          <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-rose-300   transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <TfiWorld />
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Learn More Anywhere
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              Discover Limitless Learning: Immerse Yourself in Knowledge from
              Anywhere, Anytime, on Any Device.{"}"}
            </div>
          </div>

          <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-rose-300   transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <IoIosPeople />
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Expert Instructor
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              Expert Guidance, Anywhere: Learn from the Best, Wherever You Are,
              on Desktop, Mobile, or Tablet.{"}"}
            </div>
          </div>

          <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-rose-300  transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <FaHandsHelping />
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              24/7 Strong Support
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              Support Around the Clock: Access Help and Advice 24/7, Anywhere in
              the World, on Any Device.{"}"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
