import React from "react";

const Header = () => {
  return (
    // <div className="flex flex-col w-full justify-center items-center h-screen bg-gradient-to-r from-teal-200 to-white">
    //   <div className="flex flex-row w-full h-screen justify-center items-center">
    //     <div className="w-3/4 lg:w-1/2  h-screen flex flex-col justify-center items-center ">
    //       <div>
    //         <p className="font-bold text-[30px] md:text-[40px] lg:text-[55px] ">
    //           Find Your
    //         </p>
    //         <p className="font-bold text-[30px] md:text-[40px] lg:text-[55px]  text-teal-400">
    //           Next Great
    //         </p>
    //         <p className="font-bold text-[30px] md:text-[40px] lg:text-[55px] ">
    //           Read
    //         </p>
    //         <span className="text-gray-400 text-[15px] md:text-[20px] ">
    //           Discover your next favorite read - eBooks, bestsellers, and more!
    //         </span>

    //         <div className="px-2 mt-1  py-1 md:py-2 rounded-lg flex flex-col sm:flex-row justify-between bg-white">
    //           <input
    //             type="text"
    //             className="py-1 sm:py-2 md:py-3 lg:py-4 border-none focus:outline-none"
    //             placeholder="Enter Youe Email"
    //           />
    //           <button className="bg-rose-400 py-3 px-4 rounded-lg text-white">
    //             Get Started Now
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-1/5 sm:w-1/4 lg:w-1/2 h-screen ">
    //       <div className="w-full h-screen relative">
    //         <img
    //           className="w-full z-10 h-full absolute hidden xl:block"
    //           src="/assets/man.png"
    //           alt="manpng"
    //         />
    //         <div className="bg-rose-300 transform translate-y-[100px] z--20 sm:z-0 translate-x-[-150px] lg:translate-x-[-10px]  xl:translate-x-[100px]  rounded-full w-[150px] h-[150px] xsm:w-[200px] xsm:h-[200px] sm:w-[250px] sm:h-[250px]  md:w-[300px] md:h-[300px]  lg:w-[400px] lg:h-[400px] xl:w-[500px] xl:h-[500px] "></div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <section
      style={{
        backgroundImage: "url('./assets/1.png')",
      }}
      className={`xl:min-h-screen bg-cover bg-no-repeat bg-center overflow-hidden`}
    >
      <div className="container relative">
        <div className="max-w-[570px] xl:pt-[297px] md:pt-20 md:pb-20 pt-28 pb-14 xl:pb-40 space-y-8">
          <h1>
            Classical{" "}
            <span
              style={{
                backgroundImage: "url('./assets/shape.svg')",
              }}
              className=" text-secondary inline-block bg-no-repeat bg-bottom"
            >
              Education
            </span>{" "}
            For The Future
          </h1>
          <div className=" plain-text text-gray leading-[30px]">
            It is long established fact that reader distracted by the readable
            content.
          </div>
          <div className="bg-white md:flex  rounded-md shadow-e1 items-center py-[10px] relative lg:pr-[10px] lg:pl-[38px] pl-4 pr-4">
            <div className="flex-1 flex items-center lg:mb-0 mb-2">
              <div className="flex-none">
                <span className=" ">
                  <img src="./assets/mail.svg" alt="mainIcon" />
                </span>
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  placeholder="Enter your mail"
                  className="border-none focus:ring-0"
                />
              </div>
            </div>
            <div className="flex-none">
              <button className="btn btn-primary w-full lg:w-auto">
                Get Started Now
              </button>
            </div>
          </div>
        </div>
        <div className="imge-box absolute xl:right-[-260px]  hidden xl:block   bottom-0  ">
          <img src="/assets/man.png" alt="manOne" />
        </div>
      </div>
    </section>
  );
};

export default Header;
