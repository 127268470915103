import React, { useState, useEffect } from "react";
import "./App.css";
import NavBar from "./components/navBar";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Loading from "./pages/Loading";
import Courses from "./pages/Courses";
import { Routes, Route, Navigate } from "react-router-dom";
import CourseDetails from "./pages/CourseDetails";
import Footer from "./components/Footer";
import CourseBookViewer from "./pages/CourseBookViewer";
import Home from "./pages/Home";

function App() {
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);

  const toggleSidebar = () => setIsOpenSidebar(!isOpenSidebar);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading process, e.g., fetching initial data
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // Assuming it takes 2 seconds to load your resources

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Sidebar isOpen={isOpenSidebar} toggleSidebar={toggleSidebar} />
      <NavBar toggleSidebar={toggleSidebar} />

      {/* <Header /> */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:id" element={<CourseDetails />} />
        <Route path="/courses/bookview" element={<CourseBookViewer />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
