import React, { useState, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

interface NavPropTypes {
  toggleSidebar: () => void;
}

const NavBar: React.FC<NavPropTypes> = ({ toggleSidebar }) => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", onScroll);

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div
      className={`py-[20px] z-25 px-[10px] sm:px-[20px] md:px-[100px] lg:px-[120px] transition-all ease-linear ${
        isScrolled ? "bg-white" : "bg-transparent"
      } w-full fixed top-0`}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <div>
          <button className="font-bold text-[25px] cursor-pointer">
            Tantra
          </button>
        </div>

        <div className="">
          <ul className="hidden lg:flex space-x-5">
            <li className="relative group">
              <div className="cursor-pointer text-gray-400 flex flex-row items-center justify-between">
                <button
                  // Show on hover
                  // Hide on hover out
                  className="px-1 text-[20px]"
                >
                  Home
                </button>
                <IoIosArrowDown />
              </div>

              <div
                className={`absolute hidden  group-hover:flex flex-col bg-gray-300 rounded-lg  cursor-pointer w-[120px]`}
              >
                <ul className="hover:bg-gray-300 rounded-lg">
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-tl-lg rounded-tr-lg text-white">
                    Home 1
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 text-white">
                    Home 2
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-bl-lg rounded-br-lg text-white">
                    Home 3
                  </li>
                </ul>
              </div>
            </li>
            <li className="relative group">
              <span className="cursor-pointer text-gray-400 flex flex-row items-center justify-between">
                <button className="px-1 text-[20px]">Pages</button>
                <IoIosArrowDown />
              </span>
              <div className="absolute rounded-lg bg-gray-200 hidden group-hover:flex flex-col  cursor-pointer w-[120px] ">
                <ul className="hover:bg-gray-300 rounded-lg">
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-tl-lg rounded-tr-lg text-white">
                    Pages 1
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 text-white">
                    Pages 2
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-bl-lg rounded-br-lg text-white">
                    Pages 3
                  </li>
                </ul>
              </div>
            </li>
            <li className="relative group">
              <span className="cursor-pointer text-gray-400 flex flex-row items-center justify-between">
                <button className="px-1 text-[20px]"> Courses</button>
                <IoIosArrowDown />
              </span>

              <div className="absolute rounded-lg hidden bg-gray-200 group-hover:flex flex-col  cursor-pointer w-[120px]">
                <ul className="hover:bg-gray-300 rounded-lg">
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-tl-lg rounded-tr-lg text-white">
                    <Link to="/courses">Courses 1</Link>
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 text-white">
                    <Link to="/courses">Courses 2</Link>
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-bl-lg rounded-br-lg text-white">
                    <Link to="/courses">Courses 3</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="relative group">
              <span className="cursor-pointer text-gray-400 flex flex-row items-center justify-between">
                <button className="px-1 cursor-pointer text-[20px]">
                  {" "}
                  Blogs
                </button>
                <IoIosArrowDown />
              </span>

              <div className="absolute rounded-lg bg-gray-200 hidden group-hover:flex flex-col  cursor-pointer w-[120px]">
                <ul className="hover:bg-gray-300 rounded-lg">
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-tl-lg rounded-tr-lg text-white">
                    Blogs 1
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 text-white">
                    Blogs 2
                  </li>
                  <li className="py-2 px-2 hover:bg-rose-500 rounded-bl-lg rounded-br-lg text-white">
                    Blogs 3
                  </li>
                </ul>
              </div>
            </li>
            <li className="relative group">
              <span className="cursor-pointer text-gray-400 flex flex-row items-center justify-between">
                <button className="px-1 text-[20px]">Contacts</button>
              </span>
            </li>
          </ul>
        </div>

        <div>
          <button className="hidden lg:flex bg-rose-400 px-[15px] py-[10px] rounded-lg text-white font-bold cursor-pointer">
            Start Free Trial
          </button>
          <div className="flex lg:hidden">
            <AiOutlineMenu onClick={toggleSidebar} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
